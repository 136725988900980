import './css/main.css';
import { Elm } from './Main.elm';
import setupDraggable from './js/draggable';
import * as serviceWorker from './js/serviceWorker';

const app = Elm.Main.init({
  node: document.getElementById('root')
  , flags: {
    apiUrl: process.env.ELM_APP_API_URL,
    loginUrl: process.env.ELM_APP_LOGIN_URL,
    dataRefetchIntervalMs: parseInt(process.env.ELM_APP_DATA_REFETCH_INTERVAL_MS),
    token: localStorage.getItem('token'),
    globalPin: process.env.ELM_APP_GLOBAL_PIN
  }
});

// Port 'touchEventRegistered'
// send notice to Elm if the user triggers a touch event
function onFirstTouch() {
  app.ports.touchEventDetected.send(true);
};
window.addEventListener('touchstart', onFirstTouch, false);

// Port 'dragConsolePrint'
// debug messages from the drag & drop component
app.ports
  .dragAndDropDebug
  .subscribe(string => { console.log('DRAG MESSAGE', string) })

// Port 'stopDetectingTouchEvents'
// we only need to know once that a human touched the screen, so we
// can stop listening when Elm tells us it has registered the touch
app.ports
  .stopDetectingTouchEvents
  .subscribe(() => {
    window.removeEventListener('touchstart', onFirstTouch, false);
  });


// Port 'dragEvents'
setupDraggable(app.ports.dragEvents.send)

// Port 'storeToken'
app.ports.storeToken.subscribe(token => {
  localStorage.setItem('token', token)
});

// Port 'logoutUser'
app.ports.logoutUser.subscribe(() => {
  const token = localStorage.getItem('token');
  removeToken();
  app.ports.tokenRemoved.send(token || '');
});

function removeToken() {
  localStorage.removeItem('token')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
